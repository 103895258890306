import Immutable from "seamless-immutable";
import { initModel } from "./initCheckout";
import get from "lodash/get";

function setState(state, newState) {
  return state.merge({
    supportOpen: newState.supportOpen,
    model: initModel(newState),
    unsaved: false,
    loading: false,
    verificationRequired: newState.verificationRequired
  });
}

const initialState = Immutable({ loading: false });

export function isZcrOrder(checkout) {
  return get(checkout, "model.formData.confirm.zcr", false);
}

export function isLoading(checkout) {
  return (checkout && checkout.loading) || false;
}

export function isUnsaved(checkout) {
  return (checkout && checkout.unsaved) || false;
}

export function billingData(state) {
  return state.model.formData.billing;
}

export function isSignedIn(state) {
  return state.model.formData.account.mode === "signed_in";
}

export function formData(state, key) {
  return state.model.formData[key].data;
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SELECT_ACCOUNT_MODE":
      return state.setIn(["model", "account", "mode"], action.formType);

    case "REFRESH_CHECKOUT_STATE":
      return setState(state, action.payload);

    case "NOTIFY_UNSAVED":
      return state.set("unsaved", action.isUnsaved);

    case "CONFIRM_NAVIGATION":
      return state.set("confirmNavigation", action.step);

    case "NAVIGATE":
      return state.without("confirmNavigation").without("unsaved");

    case "CANCEL_NAVIGATION":
      return state.without("confirmNavigation");

    case "START_LOADING":
      return state.set("loading", true);

    case "STOP_LOADING":
      return state.set("loading", false);
  }
  return state;
}
