import h from "h";
import { form, ManagedForm, CheckboxField, SubmitButton } from "../form";
import validator from "../util/validator";

import "../styles/cc-agree.scss";

function CardInfo() {
  return h(".cc-terms-info", [h("h2.bold", "Cardholder Agreement"), CardSections()]);
}

function CardSections() {
  return h(".cc-terms-info__sections", [
    h(".cc-terms-info__section", {key: "cc-terms-cct"}, [
      h("h3.bold", "Credit Card Transactions"),
      h(
        "p",
        `Your credit card information will be stored securely by our
      third-party payments provider Braintree Payments. The Cardholder agrees
      to pay the amount shown during checkout including taxes (if applicable)
      in US Dollars.`
      )
    ]),
    h(".cc-terms-info__section", {key: "cc-terms-ar"}, [
      h("h3.bold", "Automatic Renewals"),
      h(
        "p",
        `The Cardholder also agrees that Hover may use the stored payment
      credentials to renew products and services in your account if those
      product and services are set to “auto-renew”. In most cases, Hover
      automatically renews domain names and associated email addresses
      (if applicable) one (1) day prior to the expiry date for the domain name
      although some domain registries require us to renew up to thirty-five
      (35) days before expiry.`
      )
    ]),
    h(".cc-terms-info__section", {key: "cc-terms-tos"}, [
      h("h3.bold", "Terms of Service"),
      h("p", [
        "Our refund and cancellation policies can be found in our ",
        h("a", { href: "/tos" }, "Terms of Service"),
        ". Hover is located at 96 Mowat Ave., Toronto, Ontario, Canada, M6K 3M1."
      ])
    ])
  ])
}

function CheckoutCardAgreement({ onCancel, onSubmit }) {
  return h(".cc-terms", [
    h(CardInfo),
    h(CardAgreementForm, { onCancel, onSubmit, submitText: "Continue" })
  ]);
}

const fieldNames = ["agree"];

function validate(values) {
  return validator(values, {
    agree: {
      presence: {
        message: "Required"
      },
      inclusion: {
        within: [true],
        message: "Required"
      }
    }
  });
}

const CardAgreementForm = form(fieldNames, validate)(
  ({ fields, form, onCancel, onSubmit, submitText }) => {
    return h(ManagedForm, { form, onSubmit, className: "cc-terms-form" }, [
      h(CheckboxField, {
        field: fields.agree,
        label: [
          "I have read and agree to the ",
          h("strong", "Cardholder Agreement")
        ]
      }),
      h(".fieldgroup.buttons", [
        h(
          "button.btn-link.form_cancel",
          {
            type: "button",
            key: "cancel",
            onClick: onCancel
          },
          "Cancel"
        ),
        h(SubmitButton, { form, text: submitText })
      ])
    ])
  }
);

export { CheckoutCardAgreement, CardAgreementForm, CardSections };
